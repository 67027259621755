import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc, addDoc, serverTimestamp, collection } from "firebase/firestore";
import { db, firestore } from "./firebase";
import axios from 'axios';
export default class FBQueries {
    static async fetchBooking(bookingid, completion) {
        try {
            // Reference to the specific document in the collection
            const docRef = doc(db, "bookings", bookingid);

            // Fetch the document
            const docSnap = await getDoc(docRef);

            // Check if the document exists
            if (docSnap.exists()) {
                // Document data will be in docSnap.data()
                completion(docSnap.data())
            } else {
                completion(null)
                // Document not found
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error getting document:", error);
        }
    }

    static async approveBooking(id, completion) {
        try {
            let data = {
                approved: true
            }
            const docRef = doc(db, 'bookings', id); // Replace with your collection name
            await updateDoc(docRef, data);
            completion(true)
        } catch (error) {
            completion(false)
            console.error("Error updating document: ", error);
        }
    };

    static async rejectBooking(id, completion) {
        try {
            let data = {
                rejected: true
            }
            const docRef = doc(db, 'bookings', id); // Replace with your collection name
            await updateDoc(docRef, data);
            completion(true)
        } catch (error) {
            completion(false)
            console.error("Error updating document: ", error);
        }
    };

    static async modifyBooking(id, eta, price, completion) {
        try {
            let data = {
                bookingModified: true,
                eta: eta,
                price: price
            }
            const docRef = doc(db, 'bookings', id); // Replace with your collection name
            await updateDoc(docRef, data);
            completion(true)
        } catch (error) {
            completion(false)
            console.error("Error updating document: ", error);
        }
    };

    static async fetchUserDetails(uid, completion) {
        try {
            // Reference to the specific document in the collection
            const docRef = doc(db, "users", uid);

            // Fetch the document
            const docSnap = await getDoc(docRef);

            // Check if the document exists
            if (docSnap.exists()) {
                // Document data will be in docSnap.data()
                completion(docSnap.data())
            } else {
                completion(null)
                // Document not found
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error getting document:", error);
        }
    }

    static async sendPushTouser(user, title, message) {
        if (user.token != null) {
            const reqBody = `fcmTokens=${encodeURIComponent(user.token)}` + `&title=${encodeURIComponent(title)}` + `&body=${encodeURIComponent(message)}`;

            axios({
                method: "post",
                headers: { 'Content-type': 'application/x-www-form-urlencoded' },
                url: "https://us-central1-drvr.cloudfunctions.net/sendPushNotificationTouser",
                data: reqBody
            }).then((response) => {
                console.log(response);
            })
                .catch((e) => {
                });
        }
        FBQueries.addNotification(user.uid, title, message)
    }


    static async addNotification(uid, title, description) {
        try {
            // Reference the collection
            const docRef = await addDoc(collection(db, 'notifications'), {
                title: title,
                description: description,
                uid: uid,
                createdAt: serverTimestamp()
            });
            console.log('Document written with ID: ', docRef.id);
            //alert('Document added successfully!');
        } catch (e) {
            console.error('Error adding document: ', e);
            //alert('Error adding document');
        }
    };
}